var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "print-hide" },
      [
        _c("FilterSelector", {
          attrs: {
            disablePrint: this.tableData.length === 0,
            filterForReport: true,
            buttonsToDisplay: [
              _vm.buttonEnum.YESTERDAY,
              _vm.buttonEnum.SEVEN_DAYS,
              _vm.buttonEnum.MONTH_TO_DATE,
              _vm.buttonEnum.CUSTOM,
            ],
            extraFunction: true,
            extraFunctionLabel: "IMPRIMIR",
            disableExtraFunction: this.tableData.length === 0,
          },
          on: {
            searchData: function ($event) {
              return _vm.getVotedSongs()
            },
            extraFunction: function ($event) {
              return _vm.printReport()
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass:
          "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 print-hide",
      },
      [_c("Spinner")],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading",
          },
        ],
        staticClass: "print-hide",
      },
      [
        this.tableData.length > 0
          ? _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
              },
              [
                _c(
                  "md-card",
                  [
                    _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-blue",
                      },
                      [
                        _c("div", { staticClass: "card-icon" }, [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("thumbs_up_down"),
                          ]),
                        ]),
                        _c("h4", [_vm._v("Votação")]),
                      ]
                    ),
                    _c("md-card-content", [
                      _c("div", { staticClass: "md-layout" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c("md-table", {
                              attrs: {
                                "table-header-color": "blue",
                                "md-sort": _vm.currentSort,
                                "md-sort-order": _vm.currentSortOrder,
                              },
                              on: {
                                "update:mdSort": function ($event) {
                                  _vm.currentSort = $event
                                },
                                "update:md-sort": function ($event) {
                                  _vm.currentSort = $event
                                },
                                "update:mdSortOrder": function ($event) {
                                  _vm.currentSortOrder = $event
                                },
                                "update:md-sort-order": function ($event) {
                                  _vm.currentSortOrder = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "md-table-row",
                                    fn: function ({ item }) {
                                      return _c(
                                        "md-table-row",
                                        {},
                                        [
                                          _c(
                                            "md-table-cell",
                                            { attrs: { "md-label": "Título" } },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: { "md-label": "Artista" },
                                            },
                                            [_vm._v(_vm._s(item.artist))]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: {
                                                "md-label": "Curti",
                                                "md-sort-by":
                                                  "percentual_likes",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    item.total_likes
                                                  )
                                                ) +
                                                  " (" +
                                                  _vm._s(
                                                    item.percentual_likes
                                                  ) +
                                                  "%)"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: {
                                                "md-label": "Não Curti",
                                                "md-sort-by":
                                                  "percentual_dislikes",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    item.total_dislikes
                                                  )
                                                ) +
                                                  " (" +
                                                  _vm._s(
                                                    item.percentual_dislikes
                                                  ) +
                                                  "%)"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: {
                                                "md-label": "Total",
                                                "md-sort-by": "total",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    item.total
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            [
                                              _c("el-button", {
                                                attrs: {
                                                  round: "",
                                                  type: "danger",
                                                  icon: "el-icon-delete",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteMusic(item)
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                2208706578
                              ),
                              model: {
                                value: _vm.tableData,
                                callback: function ($$v) {
                                  _vm.tableData = $$v
                                },
                                expression: "tableData",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        this.tableData.length > 0
          ? _c(
              "div",
              [
                _c(
                  "md-card",
                  [
                    _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-blue",
                      },
                      [
                        _c("div", { staticClass: "card-icon" }, [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("clear_all"),
                          ]),
                        ]),
                        _c("h4", [_vm._v("Total por plataforma")]),
                      ]
                    ),
                    _c("md-card-content", [
                      _c("div", { staticClass: "md-layout" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c("md-table", {
                              attrs: { "table-header-color": "blue" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "md-table-row",
                                    fn: function ({ item }) {
                                      return _c(
                                        "md-table-row",
                                        {},
                                        [
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: { "md-label": "Android" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    item.Android
                                                  )
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    Math.round(
                                                      (item.Android * 100) /
                                                        (item.Android +
                                                          item.IOS +
                                                          item.Windows_Phone +
                                                          item.Web)
                                                    )
                                                  ) +
                                                  "%"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            { attrs: { "md-label": "IOS" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    item.IOS
                                                  )
                                                ) +
                                                  " (" +
                                                  _vm._s(
                                                    Math.round(
                                                      (item.IOS * 100) /
                                                        (item.Android +
                                                          item.IOS +
                                                          item.Windows_Phone +
                                                          item.Web)
                                                    )
                                                  ) +
                                                  "%)"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            {
                                              attrs: {
                                                "md-label": "Windows Phone",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    item.Windows_Phone
                                                  )
                                                ) +
                                                  " (" +
                                                  _vm._s(
                                                    Math.round(
                                                      (item.Windows_Phone *
                                                        100) /
                                                        (item.Android +
                                                          item.IOS +
                                                          item.Windows_Phone +
                                                          item.Web)
                                                    )
                                                  ) +
                                                  "%)"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-table-cell",
                                            { attrs: { "md-label": "Web" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    item.Web
                                                  )
                                                ) +
                                                  " (" +
                                                  _vm._s(
                                                    Math.round(
                                                      (item.Web * 100) /
                                                        (item.Android +
                                                          item.IOS +
                                                          item.Windows_Phone +
                                                          item.Web)
                                                    )
                                                  ) +
                                                  "%)"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                1962822684
                              ),
                              model: {
                                value: _vm.tableData2,
                                callback: function ($$v) {
                                  _vm.tableData2 = $$v
                                },
                                expression: "tableData2",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 print-hide",
        staticStyle: { padding: "0" },
      },
      [
        _c(
          "md-card",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.msgError,
                expression: "msgError",
              },
            ],
            staticStyle: { "text-align": "center" },
          },
          [
            _c("md-card-content", [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Sem informações pra serem exibidas."),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "md-layout", attrs: { id: "impressao" } }, [
      _vm._m(0),
      _c("div", { staticClass: "titleRight" }, [
        _c("b", { staticClass: "nameRadio" }, [
          _vm._v(
            _vm._s(_vm.nameRadioReport ? _vm.nameRadioReport : "MobRadio")
          ),
        ]),
        _vm._v("   "),
        _c("img", {
          staticClass: "logoRadio",
          attrs: { src: _vm.logo, alt: "" },
        }),
      ]),
      _c("div", [
        _c("span", [
          _vm._v(
            "Período: " +
              _vm._s(_vm.periodStart) +
              " até: " +
              _vm._s(_vm.periodEnd) +
              " "
          ),
        ]),
      ]),
      this.tableData.length > 0
        ? _c("div", { staticClass: "contentBody impressao" }, [
            _c("div", { staticClass: "divTable" }, [
              _c("h4", [_vm._v("Votação")]),
              _c(
                "span",
                {
                  staticStyle: {
                    "padding-left": "5px",
                    "font-style": "italic",
                  },
                },
                [_vm._v("Ordenado por " + _vm._s(_vm.orderBy))]
              ),
              _c(
                "div",
                {
                  staticClass: "md-content md-table md-theme-default",
                  attrs: { "table-header-color": "blue" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-content md-table-content md-scrollbar md-theme-default",
                    },
                    [
                      _c("table", [
                        _vm._m(1),
                        _vm._m(2),
                        _c(
                          "tbody",
                          _vm._l(_vm.tableData, function (item, index) {
                            return _c(
                              "tr",
                              { key: index, staticClass: "md-table-row" },
                              [
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                ]),
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [_vm._v(" " + _vm._s(item.artist) + " ")]
                                  ),
                                ]),
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatNumber")(
                                              item.total_likes
                                            )
                                          ) +
                                          " (" +
                                          _vm._s(item.percentual_likes) +
                                          "%) "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatNumber")(
                                              item.total_dislikes
                                            )
                                          ) +
                                          " (" +
                                          _vm._s(item.percentual_dislikes) +
                                          "%) "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatNumber")(item.total)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "divTable" }, [
              _c("h4", [_vm._v("Total por plataforma")]),
              _c(
                "div",
                {
                  staticClass: "md-content md-table md-theme-default",
                  attrs: { "table-header-color": "blue" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-content md-table-content md-scrollbar md-theme-default",
                    },
                    [
                      _c("table", [
                        _vm._m(3),
                        _vm._m(4),
                        _c(
                          "tbody",
                          _vm._l(_vm.tableData2, function (item, index) {
                            return _c(
                              "tr",
                              { key: index, staticClass: "md-table-row" },
                              [
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatNumber")(item.Android)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatNumber")(item.IOS)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatNumber")(
                                              item.Windows_Phone
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatNumber")(item.Web)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../../public/img/logo-mobradio-black.png"),
          alt: "",
        },
      }),
      _c("h1", [_vm._v("Relatório de Votação Musical")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Título"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Artista"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Curti"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Não Curti"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Total"),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tfoot", { staticClass: "report-footer" }, [
      _c("tr", [
        _c("td", { attrs: { colspan: "6" } }, [
          _vm._v(" Fonte de dados: MobRadio Analytics   "),
          _c("img", {
            staticClass: "footerIcon",
            attrs: { src: require("../../../../public/img/favicon.png") },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Android"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [_c("div", { staticClass: "md-table-head-label" }, [_vm._v("iOS")])]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Windows Phone"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [_c("div", { staticClass: "md-table-head-label" }, [_vm._v("Web")])]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tfoot", { staticClass: "report-footer" }, [
      _c("tr", [
        _c("td", { attrs: { colspan: "6" } }, [
          _vm._v(" Fonte de dados: MobRadio Analytics   "),
          _c("img", {
            staticClass: "footerIcon",
            attrs: { src: require("../../../../public/img/favicon.png") },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }